import { graphql } from 'gatsby';
import React from 'react';

import { getImage } from '../../../../utils/sanityTypes';
import VideoBox from '../../VideoBox';
import { VideoBoxSectionProps } from './types';

const VideoBoxSection: React.VFC<VideoBoxSectionProps> = ({
  title,
  content,
  backgroundImage,
  video,
}) => {
  const { image, mobileImage } = backgroundImage ?? {};
  const { url: videoUrl, title: videoTitle } = video ?? {};

  return (
    <VideoBox
      data={{
        title: title ?? '',
        contentRichText: content ?? undefined,
        backgroundImage:
          {
            image: getImage(image),
            mobileImage: getImage(mobileImage),
          } ?? null,
        video:
          {
            url: videoUrl ?? '',
            title: videoTitle ?? '',
          } ?? null,
      }}
    />
  );
};

export const fragment = graphql`
  fragment VideoBoxSectionFragment on SanityVideoBoxSection {
    _key
    _type
    title
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    backgroundImage {
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    video {
      title
      url
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default VideoBoxSection;
