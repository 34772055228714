import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './styles';
import { VideoBoxProps } from './types';

import Image from '../../atoms/Image';
import Heading from '../../molecules/Heading';
import { IconPlay, IconCloseCircle } from '../../../assets/svg';
import { LinkMode } from '../../atoms/Link/enums';
import Video from '../../atoms/Video';

const VideoBox: React.FC<VideoBoxProps> = ({ data }) => {
  const {
    title,
    contentRichText,
    backgroundImage: { mobileImage, image },
    video: { url: videoUrl, title: videoTitle },
  } = data;
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <Styled.VideBoxWrapper>
      <Styled.VideoBox pt={['7.5rem', '0']} pb={['7.5rem', '0']}>
        <Styled.VideoBoxMedia>
          <Styled.VideoBoxOverlay />
          <Styled.VideoBoxImage isMobileHidden={!!mobileImage}>
            <Image {...image} />
          </Styled.VideoBoxImage>
          {mobileImage && (
            <Styled.VideoBoxImageMobile>
              <Image {...mobileImage} />
            </Styled.VideoBoxImageMobile>
          )}
        </Styled.VideoBoxMedia>
        <AnimatePresence>
          {isVideoPlaying && (
            <Styled.VideoBoxReel
              initial={{ opacity: 0 }}
              exit={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{ duration: 0.2, type: 'tween' }}
            >
              <Styled.VideoBoxReelClose
                mode={LinkMode.HasPlainLook}
                onClick={() => setIsVideoPlaying(!isVideoPlaying)}
              >
                <IconCloseCircle />
              </Styled.VideoBoxReelClose>
              <Video url={videoUrl} title={videoTitle} />
            </Styled.VideoBoxReel>
          )}
        </AnimatePresence>
        <Styled.VideoBoxInner>
          <Styled.VideoBoxContent>
            <Heading {...{ title, contentRichText }} />
            <Styled.VideoBoxAction
              mode={LinkMode.HasPlainLook}
              hasOverlayMask
              onClick={() => setIsVideoPlaying(!isVideoPlaying)}
            >
              <IconPlay />
            </Styled.VideoBoxAction>
          </Styled.VideoBoxContent>
        </Styled.VideoBoxInner>
      </Styled.VideoBox>
    </Styled.VideBoxWrapper>
  );
};

export default VideoBox;
