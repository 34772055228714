import React from 'react';
import getYouTubeID from 'get-youtube-id';

import { VideoProps } from './types';
import * as Styled from './styles';

const Video: React.FC<VideoProps> = ({ url, title }) => {
  

  return (
    <Styled.VideoIframe
      src= {url + '&autoplay=1'}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      {...{ title }}
    />
  );
};

export default Video;
