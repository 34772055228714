import styled, { css } from 'styled-components';
import { m } from 'framer-motion';

import { Inner, Box } from '../../styles/Grid/styles';
import { RichTextContainer } from '../../molecules/RichText/styles';
import { transition } from '../../../common/mixins';
import media from '../../../common/MediaQueries';
import Button from '../../atoms/Button';
import { VideoBoxImageStyledProps } from './types';

const mobileWindowHeight = `calc(100vh - 5rem)`;

const videoBoxImageStyled = css`
  ${transition('all', '320ms')}
  height: ${mobileWindowHeight};

  @media ${media.phone} {
    height: 100%;
  }
`;

export const VideoBoxMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${mobileWindowHeight};

  @media ${media.phone} {
    height: 100%;
  }
`;

export const VideoBoxImageMobile = styled.div`
  ${videoBoxImageStyled}

  @media ${media.phone} {
    display: none;
  }
`;

export const VideoBoxImage = styled.div<VideoBoxImageStyledProps>`
  ${videoBoxImageStyled}

  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      @media ${media.maxPhone} {
        display: none;
      }
    `};
`;

export const VideoBoxOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${mobileWindowHeight};
  width: 100%;
  z-index: 1;
  ${({ theme }) => theme.overlays.radial70}

  @media ${media.phone} {
    height: 100%;
  }
`;

export const VideoBoxInner = styled(Inner)`
  position: relative;
  display: flex;
  height: 100%;
  z-index: 1;
  color: ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.phone} {
    align-items: center;
  }
`;

export const VideoBoxContent = styled.div`
  text-align: center;
  max-width: 36rem;
  margin: 0 auto;

  ${RichTextContainer} {
    color: ${({ theme }) => theme.colors.additional.accentGray3};
  }
`;

export const VideoBoxAction = styled(Button)`
  ${transition()}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  padding: 0;
  background: none;
  margin: 1.5rem auto 0;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral10};
  color: ${({ theme }) => theme.colors.neutral.neutral10};
  cursor: pointer;

  svg {
    ${transition('all', '280ms')}
    font-size: 1.875rem;
    width: 0.8em;
  }

  @media ${media.phone} {
    &:hover svg {
      transform: scale(1.5);
    }
  }
`;

export const VideoBoxReelClose = styled(Button)`
  ${transition('all', '200ms')}
  position: absolute;
  right: 1rem;
  bottom: 3.725rem;
  font-size: 1.625rem;
  transform: translateY(1rem);
  color: ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.phone} {
    opacity: 0;
    bottom: 3.125rem;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const VideoBoxReel = styled(m.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: ${mobileWindowHeight};
  width: 100%;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.neutral.neutral100};

  @media ${media.phone} {
    height: 100%;
    &:hover ${VideoBoxReelClose} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const VideoBox = styled(Box)`
  position: relative;
  height: ${mobileWindowHeight};
  overflow: hidden;

  @media ${media.phone} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &:hover ${VideoBoxImage}, &:hover ${VideoBoxImageMobile} {
      transform: scale(1.1);
    }
  }
`;

export const VideBoxWrapper = styled.div`
  @media ${media.phone} {
    position: relative;
    padding-top: 56.25%;
  }
  @media ${media.desktop} {
    padding-top: 0;
    height: 100vh;
  }
`;
